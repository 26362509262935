<template>
  <div class="fullwidth">
    <DashboardMap
      v-if="dashboard"
      :polygons="clusterBoundaries"
      :dashboard="dashboard"
      v-on:set_current_cluster_id="set_current_cluster_id"
    />
    <div v-if="dashboard" class="results_parent">
      <h1>{{ dashboard.name }}</h1>
      <ReboundaryClusterOptions
        :dashboard="dashboard"
        @dashboard_query_parameters="run_dashboard"
        :debug_mode="dashboard['schema']['debug_mode']"
      />
      <b-card v-if="dataset" class="centred">
        <b-button
          size="lg"

          v-for="(cluster, cluster_id) in this.dataset['cluster_information']" :key="cluster_id"
          @click="set_current_cluster_id(cluster_id);"
          :class="`cluster_select_button is_selected_${current_cluster_id == cluster_id}`"
          :style="`background-color: ${colours[cluster_id]}`"
        >
          {{ convert_cluster_id_to_label(cluster_id) }}
        </b-button>
      </b-card>

      <Loading :is_loading="loading" :message="'Loading your dashboard...'" />

      <b-row v-if="dataset" class="card-container" align-h="around">
        <DashboardStatistic
          v-for="statistic in dashboard['schema']['statistics']"
          v-bind:key="statistic['id']"
          :statistic="statistic"
          :dataset="dataset"
          :current_cluster="current_cluster"
          :current_cluster_id="current_cluster_id"
          :debug_mode="dashboard['schema']['debug_mode']"
        />
      </b-row>
      <b-alert variant="danger" show v-if="errorMessage">
        <h3>Error Loading Dashboard</h3>
        <p>An error occurred while loading your Dashboard. Please try again later.</p>

        <h5>Details:</h5>
        {{errorMessage}}</b-alert>
    </div>
  </div>
</template>

<script>
import DashboardMap from '@/components/DashboardMap.vue';
import DashboardStatistic from '@/components/DashboardStatistic.vue';
import ReboundaryClusterOptions from '@/components/ReboundaryClusterOptions.vue';
import Loading from '@/components/Loading.vue';
import DashboardService from '../services/dashboard.service';
import colours from '@/translation/cluster_colour_codes.js';

export default {
  name: 'Dashboard',
  components: {
    DashboardMap,
    DashboardStatistic,
    ReboundaryClusterOptions,
    Loading
  },
  data() {
    return {
      loading: true,
      dashboard: null,
      content: '',
      dataset: null,
      clusterBoundaries: [],
      schema: null,
      current_cluster_id: 0,
      dashboard_disabled: false,
      colours,

      // Dataset selection. See the computed value dataset_options
      datasets_selected: {},
      errorMessage: null
    };
  },
  computed: {
    current_cluster() {
      if (this.dataset) {
        return this.dataset['cluster_information'][this.current_cluster_id];
      } else {
        return null;
      }
    },
    cluster_ids() {
      if (this.dataset) {
        let values = [];
        for (let key in this.dataset['cluster_information']) {
          let label = key;
          if (!isNaN(key)) {
            // if its a number
            label = `Cluster ${parseInt(key) + 1}`; // our numbering starts at 0, which isn't user friendly
          }
          values.push({
            text: label,
            value: key
          });
        }
        return values;
      } else {
        return null;
      }
    }
  },
  methods: {
    is_active_cluster(cluster_id){
      if (cluster_id === this.current_cluster_id){
        return "active_cluster_button"
      }else{
        return ""
      }
    },
    convert_cluster_id_to_label(key) {
      if (!isNaN(key)) {
        // if its a number
        return `Cluster ${parseInt(key) + 1}`; // our numbering starts at 0, which isn't user friendly
      } else {
        return key;
      }
    },
    async run_dashboard(query_data) {
      this.dataset = false; // Turn off previous dataset. This triggers 'loading'
      this.loading = true
      if (this.dashboard_disabled) {
        // Used for testing, as we don't want to be loading the dashboard constantly
        console.error(
          'Dashboard disabled because this.dashboard_disabled is true! (in DashboardSingle.vue)'
        );
        return;
      }
      try {
        await this.$store.dispatch('dashboard/getDashboardData', {
          dashboardId: this.$route.params.dashboard_name,
          parameters: query_data
        })
        this.dataset = this.$store.state.dashboard.data
        this.clusterBoundaries = this.$store.state.dashboard.data.cluster_boundaries
        this.set_current_cluster_id(0)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
        this.errorMessage = error.toString()
      }


      // DashboardService.runDashboard(
      //   this.$route.params.dashboard_name,
      //   query_data
      // ).then(
      //   response => {
      //     this.dataset = response.data;
      //     this.groups = this.dataset['groups'];
      //   },
      //   error => {
      //     this.dataset = error;
      //     console.log("Error retriving dashboard")
      //     console.log({error})
      //     this.content =
      //       (error.response && error.response.data) ||
      //       error.message ||
      //       error.toString();
      //   }
      // );
    },
    set_current_cluster_id(new_current_cluster_id) {
      console.log('Set Clsuter: ', new_current_cluster_id)
      this.$store.commit('dashboard/selectCluster', new_current_cluster_id)
      // this.current_cluster_id = new_current_cluster_id;
    }
  },
  async mounted() {
    // DashboardService.getDashboardSchema(this.$route.params.dashboard_name).then(
    //   response => {
    //     this.dashboard = response.data['dashboard'];
    //     this.run_dashboard({}); // Start with default parameters
    //   },
    //   error => {
    //     this.content =
    //       (error.response && error.response.data) ||
    //       error.message ||
    //       error.toString();
    //   }
    // );
    await this.$store.dispatch('dashboard/getDashboardSchema', this.$route.params.dashboard_name)
    this.dashboard = this.$store.state.dashboard.schema
    await this.run_dashboard({})
  },
  watch: {
    '$store.state.dashboard.selectedCluster': function (newCluster) {
      this.current_cluster_id = newCluster
    }
  }
};
</script>

<style scoped>

.card-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
h1 {
  font-size: 64pt;
  margin: auto;
  text-align: center;
}
.fullwidth {
  margin: 0;
  border: 0;
  width: 100%;
  background-color: #e3e3e4;
  background: linear-gradient(180deg, #e3e3e4 0%, #c7c7c7 100%);
}

.results_parent {
  width: 80%;
  min-width: 400pt;
  margin: auto;
  padding: 16pt;
}

.centred {
  margin: auto;
  text-align: center;
}

.cluster_select_button {
  text-transform: capitalize;
  margin: 4pt;
}

.is_selected_true{
  border: 3px solid rgb(82, 82, 82);
  box-shadow: 2px solid grey;
}
</style>
