<template>
  <div>
    <b-row>
      <b-col>
        <h2>{{ dashboard.boundary_name }}</h2>
      </b-col>
      <b-col>
        <b-button v-b-toggle.collapse-1 variant="primary" class="pull_right">Advanced Options</b-button>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class>
      <b-card>
        <template v-slot:header>
          <h3>Set Advanced options</h3>
        </template>
        <b-alert v-if="!dashboard.schema.datasets">
          This dashboard has not yet been setup correctly yet. Click "Edit Dashboard Schema" to update or fix the schema.
        </b-alert>
        <b-alert :show="debug_mode" variant="success">
          Debug mode is on. You'll see extra feedback and information in components. You can turn this off in the schema by setting
          "debug_mode": false at the top-level in the dashboard editor.
        </b-alert>
        <b-card-text v-if="dashboard.schema.datasets">
          <b-form-group>
            <h3>Select Datasets:</h3>
            <b-row>
              <b-col cols="10">
                <b-form-checkbox
                  v-for="option in dataset_options"
                  :key="option.value"
                  v-model="selected_datasets[option.value]"
                  button
                  button-variant="primary"
                  class="dataset_button"
                >
                  <!-- Didn't do a b-form-checkbox-group because they didn't wrap well when there are lots of datasets -->
                  {{ option.text }}
                </b-form-checkbox>
              </b-col>
              <b-col>
                <b-button
                  variant="danger"
                  @click="selected_datasets = {}"
                  class="dataset_button"
                  size="lg"
                >
                  <font-awesome-icon icon="redo" />
                  <br />Reset
                  Datasets
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <h3>Select Date:</h3>
          <b-input-group prepend="Select dashboard date:" class="narrow_options">
            <b-form-datepicker id="dashboard_date" v-model="dashboard_date"></b-form-datepicker>
            <b-input-group-append>
              <b-button @click="dashboard_date = new Date().toISOString().slice(0, 10)">Now</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-checkbox
            v-model="do_comparison"
            name="check-button"
            switch
            size="lg"
          >{{ do_comparison_label }}</b-form-checkbox>

          <b-input-group
            v-show="do_comparison"
            prepend="Select comparison date:"
            class="narrow_options"
          >
            <b-form-datepicker id="compare_date" v-model="compare_date"></b-form-datepicker>
            <b-input-group-append>
              <b-button @click="dashboard_date = new Date().toISOString().slice(0, 10)">Now</b-button>
            </b-input-group-append>
          </b-input-group>

          <h3>Select Granularity:</h3>
          <b-form-group label="How many clusters?">
            <b-form-radio-group
              id="cluster_precision"
              v-model="granularity"
              :options="cluster_precision_options"
              button-variant="outline-primary"
              size="lg"
              buttons
              name="cluster_precision_options"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group
            label="Cache Bust?"
            description="Normally leave this off. If your dashboard isn't updating with new data, try this first."
          >
            <b-form-checkbox
              v-model="cache_buster"
              name="check-button"
              switch
              size="lg"
            >Disable cache</b-form-checkbox>
          </b-form-group>
        </b-card-text>

        <template v-slot:footer>
          <b-button :href="'/home/dashboard/edit/' + dashboard.id">Edit Dashboard Schema</b-button>
          <b-button
            @click="run_dashboard_query()"
            class="pull_right dataset_button"
            variant="success"
            size="lg"
          >Update your Reboundary</b-button>
        </template>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'ReboundaryClusterOptions',
  props: ['dashboard', 'debug_mode'],
  data() {
    return {
      selected_datasets: {},
      dashboard_date: new Date().toISOString().slice(0, 10), // Current date in YYYY-MM-DD format.
      compare_date: new Date().toISOString().slice(0, 10), // Current date in YYYY-MM-DD format.
      do_comparison: false,
      cache_buster: false,

      granularity: 3,
      cluster_precision_options: [
        { text: 'Low', value: '3' },
        { text: 'Medium', value: '5' },
        { text: 'High', value: '7' }
      ]
    };
  },
  computed: {
    dataset_options() {
      if (this.dashboard['schema'] && this.dashboard['schema']['datasets']) {
        let options = [];
        let unique_dataset_names = this.dashboard['schema']['datasets'].filter(
          (v, i, a) => a.indexOf(v) === i
        );
        for (let dataset_name of unique_dataset_names) {
          options.push({
            text: dataset_name,
            value: dataset_name,
            selected: false
          });
        }
        return options;
      } else {
        return [];
      }
    },
    do_comparison_label() {
      if (this.do_comparison) {
        return 'Comparison between two dates';
      } else {
        return 'Single date only';
      }
    }
  },
  methods: {
    run_dashboard_query() {
      let selected_datasets = [];
      for (let dataset_name in this.selected_datasets) {
        if (this.selected_datasets[dataset_name]) {
          selected_datasets.push(dataset_name);
        }
      }
      this.$emit('dashboard_query_parameters', {
        datasets: selected_datasets,
        request_type: 'cluster',
        k: this.granularity,
        start_date: this.dashboard_date,
        compare_date: this.compare_date,
        do_compare: this.do_comparison,
        cache_buster: this.cache_buster
      });
    }
  }
};
</script>

<style scoped>
.dataset_button {
  padding: 4pt;
  margin-left: 4pt;
  margin-top: 0;
  text-transform: capitalize;
}

.pull_right {
  float: right;
}

.active {
  border: 1px solid black;
}
.narrow_options {
  width: 50%;
  min-width: 400pt;
  margin-top: 4pt;
  margin-bottom: 4pt;
}

.input-group-text {
  width: 160pt;
}
</style>
